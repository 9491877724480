.header {
    /* background-color: #002cc1; */
    background: url("../media/images/header-bg.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    color: white;
    padding: 100px 30px;
    text-align: center;
  font-family: "Jersey 25";
}

.header>div{
    display: grid;
    /* grid-template-columns: 1fr 1fr; */
    display: flex;
    justify-content: center;
    /* flex-direction: row-reverse; */
    gap: 50px;
    align-items: center;
}
.header-heading-1 > span {
  color: red;
}

.header-heading-1 {
  font-size: 6rem;
  font-weight: 600;
}

.header-heading-2 {
  font-size: 2rem;
  min-width: 600px;
}

.header-button {
  font-size: 2rem;
  margin-top: 30px;
}

.header-button > a {
  border: solid;
  padding: 5px 20px;
  color: white;
  text-decoration: none;
}
.header-button > a:hover{
  text-decoration: underline;
}

.header-col-2>img{
    width: 400px;
}



@media only screen and (max-width: 800px) {
  .header {
    padding: 50px 30px;
    text-align: center;
  }
  
.header>div{
    display: grid;
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
    align-items: center;
}


.header-col-2>img{
    width: 100%;
}


  .header-heading-1 > span {
    color: red;
  }

  .header-heading-1 {
    font-size: 4rem;
    font-weight: 600;
  }
  
  .header-heading-2 {
    font-size: 1.8rem;
    min-width: 200px;
    width: auto;
    display: flex;
    height: 90px;
    justify-content: center;
    align-items: center;
  }

  .header-button {
    font-size: 1.8rem;
    margin-top: 20px;
  }
  .header-button > span {
    border: solid;
    padding: 5px 20px;
  }
}
