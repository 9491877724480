
.header-numbers {
    padding: 40px 0;
    font-family: "Jersey 25";
    background: #a4b9ff37;
    background: #6773ff32;
}
.header-numbers>div {
    margin: auto;
    max-width: 1170px;
    
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
  }
  .header-numbers-col {
    text-align: center;
  }
  .header-numbers-col-inc {
    font-size: 2rem;
    font-weight: 700;
    
  }
  .header-numbers-col-inc > span {
    color: #001c79;
    font-size: 3.5rem;
  }
  .header-numbers-col-txt {
    font-size: 2rem;
    font-weight: 400;
  }
  .header-numbers-col-txt>span{
    display: none;
  }
  
@media only screen and (max-width: 700px) {
    
  .header-numbers {
    padding: 20px 5px;
  }

  .header-numbers-col-inc {
    font-size: 1.5rem;
  }

  .header-numbers-col-inc span {
    font-size: 2rem;
  }

  .header-numbers-col-txt {
    font-size: 1.4rem;
    line-height: .6rem;
    margin-top: 10px;
  }

  .header-numbers-col-txt>span{
    display: block;
  }
  
}
