.welcome-comp{
    background-color: #6fc3ff62;
    background: #bac9ff2f;
    background: #6773ff32;


}
.welcome-comp>div{
    max-width: 1200px;
    margin: auto;
    padding: 70px 0;
}
.welcome-comp-heading{
    font-size: 2.2rem;
    text-align: center;
    font-weight: 700;
}
.welcome-comp-heading>span{
    color: #001c79;
}

.welcome-comp-content{
    display: grid;
    grid-template-columns: 3fr 5fr;
    padding: 20px;
}

.welcome-comp-content-col-1{
    background: #dff2ff;
    background: #00053b32 ;
    background: #000326;
    background: url("../media/images/mogambo\ khush\ hua....png");
    background-position: center;
    background-size: cover;

border-radius: 20px;
    margin: 0 30px;
}


.welcome-comp-content-col-2-button{
    margin-top: 20px;
}
.welcome-comp-content-col-2-button>a:hover{
    text-decoration: underline;
}
.welcome-comp-content-col-2-button>a{
    text-decoration: none;
    background-color: #000c94;
    color: white;
    padding: 8px 30px;
    border-radius: 10px;
    width: 250px;
    text-align: center;
    font-size: 1.2rem;
    font-weight: 600;
    box-shadow: 4px 4px 6px 0 #ffffff80, -4px -4px 6px 0 #747d8880, inset -4px -4px 6px 0 #fff3, inset 4px 4px 6px 2px #0006;
}

/* ------------------------------ */

.welcome-comp-content-col-2-heading-0{
    font-size: 1.3rem;
    font-weight: 600;
}
.welcome-comp-content-col-2-text{
    margin-bottom: 5px;
}
.welcome-comp-content-col-2-heading-1{
    font-size: 1.2rem;
    font-weight: 700;
    margin-top: 20px;
}
.welcome-comp-content-col-2-points{
    display: grid;
    gap: 20px;
    padding: 10px 0 20px 0;
}
.welcome-comp-content-col-2-points>h2{
    display: flex;
    align-items: center;
    gap: 10px;
    
font-size: 1rem;
font-weight: 400;
}

.welcome-comp-content-col-2-points img{
    width: 25px;
    display: flex;
    align-items: center;
}


.welcome-comp-content-col-2-heading-2{
    font-weight: 600;
    /* margin-top: 5px; */
}

@media only screen and (max-width: 800px) {
    .welcome-comp>div{
        padding: 40px 15px;
    }
    .welcome-comp-heading{
        font-size: 1.8rem;
    }
    
    .welcome-comp-content{
        grid-template-columns: 1fr;
        padding: 0px;
    }
     
    .welcome-comp-content-col-1{
        margin: 0px;
        height: 300px;
        margin: 10px 0 20px 0;
    }

    .welcome-comp-content-col-2-points{
        display: grid;
        gap: 10px;
        padding: 10px 0 0;
    }
    
    .welcome-comp-content-col-2-button>a{
        font-size: 1rem;
         }
    
    
}