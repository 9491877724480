.navbar>div{
    max-width: 1200px;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1.5rem;
    padding: 20px;
    font-family: "Jersey 25";

}

.navbar-logo{
    font-weight: 700;
    font-size: 2rem;
    /* text-transform: uppercase; */
}
.navbar-logo>img{
    width: 120px;
    display: flex;
    animation: anim-popoutin 3s ease infinite;

}


@keyframes anim-popoutin {
    0% {
      opacity: 1;
      /* text-shadow: 0 0 0 #4b4b4b00; */
      transform: scale(1);
  }
  50% {
    opacity: 1;
    /* text-shadow: 7px 7px 2px rgba(186, 186, 186, 0.499); */
    transform: scale(1.2);
  }
  100% {
      opacity: 1;
      /* text-shadow: 1px 0 0 #4e4e4e00; */
      transform: scale(1);
  }
  }

.navbar-menu{
    display: flex;
    gap: 50px;
}
.navbar a{
    text-decoration: none;
    color: black;
}
.navbar a:hover{
    text-decoration: underline;
}
.navbar-button{
    border: dashed;
    padding: 5px 30px;
}

@media only screen and (max-width: 800px) {
    .navbar>div{
        font-size: 1.5rem;
        padding: 10px;
    }
    
    .navbar-logo{
        font-size: 1.8rem;
    }
    .navbar-logo>img{
        width: 100px;
        margin-left: 10px;
    }
    .navbar-menu{
        display: none;
    }
    .navbar-button{
        border: dashed;
        padding: 1px 10px;
    }

}
