.why{
    /* background-color: #6fc3ff62; */
}
.why>div{
    max-width: 1200px;
    margin: auto;
    padding: 50px 0;
}

.why-heading{
    text-align: center;
    font-size: 2.2rem;
    font-weight: 700;
}
.why-heading>span{
    color: #2587cd;
    color: #001c79;
}

.why-content{
    padding: 30px 0;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
gap: 20px;
}

.why-content-col-1{
    background-color: #dbf1ff;
    /* background: #00053b32; */
background: url("../media/images/2-boys.jpeg");
background: url("../media/images/2-boys3.png");
background: url("../media/images/2-boys4.png");
    border-radius: 10px;
    background-size: cover;
    background-position: center;
}

.why-content-col-3{
    background-color: #dbf1ff;
background: url("../media/images/babu rao1.png");
    border-radius: 10px;
    background-size: cover;
    background-position: center;
}

.why-content-col-points{
    display: grid;
    gap: 15px;
    padding: 20px 0;
}
.why-content-col-points>div{
    display: flex;
    align-items: center;  
    gap: 10px;  
}
.why-content-col-points>div>span{
    display: flex;
    align-items: center;
}

.why-content-col-points>div>span>img{
    width: 22px;
}

@media only screen and (max-width: 800px) {
    
    .why>div{
        padding: 30px 15px;
    }
    
    .why-heading{
        font-size: 1.8rem;
    }
    
    
    .why-content{
        padding: 10px 0;
        grid-template-columns: 1fr;
    gap: 20px;
    }
    
    .why-content-col-1,
    .why-content-col-3{
        background-color: #6773ff32;
        border-radius: 10px;
        height: 290px;
    }
    
    .why-content-col-points{
        display: grid;
        gap: 15px;
        padding: 20px 0 0;
        /* font-size: .9rem; */
    }
    .why-content-col-points>div{
        display: flex;
        align-items: center;  
        gap: 10px;  
    }
    .why-content-col-points>div>span{
        display: flex;
        align-items: center;
    }
    
    .why-content-col-points>div>span>img{
        width: 19px;
    }
        
}


