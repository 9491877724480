
.contact-button{
  position: fixed;
  bottom: 20px;
  right: 20px;
  display: grid;
  gap: 40px;
}

/* ------------- call --------------- */

.call-button {
  animation: play 1.5s ease infinite;
  /* background-color: #fe0000; */
  border-radius: 50%;
  font-size: 1.9rem;
  /* padding: 15px 20px; */
  padding: 13px 0px;
}

.call-button-icon {
  padding: 15px 20px;
  border-radius: 50%;
  color: white;

  background-color: #fe0000;
  box-shadow: 4px 4px 6px 0 rgba(255, 255, 255, 0.5),
    -4px -4px 6px 0 rgba(116, 125, 136, 0.5),
    inset -4px -4px 6px 1px rgba(255, 255, 255, 0.227),
    inset 5px 4px 6px 2px rgba(0, 0, 0, 0.412);
}

.call-button i {
  animation: shake 1.5s ease infinite;
}

/* -------------- whatsapp ------------- */

.whatsapp-button {
  display: flex;
}
.whatsapp-button-icon {
  background: #25d366;
  /* font-size: 40px; */
  font-size: 2.5rem;

  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  padding: 15px 18px;
  border-radius: 50%;
  text-decoration: none;
  box-shadow: 4px 4px 6px 0 rgba(255, 255, 255, 0.222),
    -4px -4px 6px 0 rgba(116, 125, 136, 0.297),
    inset -4px -4px 6px 0 rgba(255, 255, 255, 0.088),
    inset 4px 4px 6px 0 rgba(0, 0, 0, 0.4);
}

.whatsapp-button-icon::before {
  content: "";
  position: absolute;
  border-radius: 50%;
  border: 5px solid #1feb6a;
  animation-name: pulse-border;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
}

@media only screen and (max-width: 800px) {

  .contact-button{
    right:  10px;
    bottom: 10px;
    gap: 15px;
}

  
  .call-button {
    font-size: 1.4rem;
    padding: 10px 0px;
  }
.call-button-icon {
  padding: 11px 15px;

}

  .whatsapp-button-icon {
    padding: 12px 14px;
    font-size: 1.8rem;
  }

  .whatsapp-button-icon::before {
    border: 3px solid #1feb6a;
  }
}
