.why-us{
    font-family: "poppins";
    padding: 80px 0 0;
    font-size: 1rem;
}
.why-us-col-content{
    max-width: 1200px;
    margin: auto;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
}

.why-us-col-1-heading-1{
    font-size: 2.4rem;
    font-weight: 800;
    text-align: center;
}

.why-us-col-1-heading-1 span{
    color: #001c79;
}

.why-us-col-1-text{
    /* margin: 20px auto; */
    font-size: 1rem;
}

.why-us-col-1-all-points{
    display: grid;
    gap: 15px;
margin: 20px 0;
}

.why-us-col-1-point{
    display: flex; 
    gap: 10px;
    align-items: center;
}
.why-us-col-1-point-icon>img{
    width: 25px;
    display: flex;
}

.why-us-col-1-point-text{
    font-weight: 500;
}


.why-us-col-1-button{
    background-color: #001c79;
    color: white;
    width: 200px;
    text-align: center;
    padding: 8px;
    border-radius: 30px;
    font-weight: 500;
    font-size: 1.3rem;
}
.why-us-col-1-button>a{
    color: white;
    text-decoration: none;
}
.why-us-col-1-button>a:hover{
    text-decoration: underline;
}

.why-us-col-2>img{
        display: flex;
        width: 100%;
}


@media only screen and (max-width: 800px) {
    .why-us{
        padding: 60px 0px 0px;
    }
    .why-us-col-content{
        display: flex;
        flex-direction: column;
        }
        
        .why-us-col-1{
            /* background-color: #eef2ff; */
            box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
            padding: 0 20px 60px;
    }
    .why-us-col-1-heading-1{
        font-size: 1.5rem;
    }
    
    .why-us-col-1-text{
        margin: 10px auto;
    }
    .why-us-col-1-text>span{
        display: none;
    }
    .why-us-col-1-button{
        background-color: #001c79;
        color: white;
        width: 150px;
        text-align: center;
        padding: 5px;
        border-radius: 30px;
        font-weight: 500;
        font-size: 1.1rem;
    }
    
    .why-us-col-2>img{
        padding: 10px 0 0 ;
        width: 100%;
        /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
    }
    
}

