.slider-component{
    background: #bac9ff2f;
    background: #6773ff32;

padding: 80px 0;
font-family: "poppins";
}
.slider-heading{
    text-align: center;
    font-size: 2.2rem;
    font-weight: 700;
}

.slider-heading>span{
    color: #001c79;
}

.slider-container img{
    width: 90%;
    margin: 30px auto;
    border-radius: 20px;
    box-shadow: 0 2px 8px 0 hsla(0,0%,51%,.973);
}

.slick-dots li button:before {
    font-size: .9rem;
}

@media only screen and (max-width: 800px) {

    .slider-component{
    padding: 50px 0;
    
    }
    
    .slider-heading{
        font-size: 1.8rem;
    }
    
    .slider-container img{
        width: 85%;
        margin: 15px auto;
    }
 
}


