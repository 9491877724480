.meme-cat{
    max-width: 1200px;
    margin: auto;
    padding: 40px 0 80px;
}
.meme-cat-heading{
    font-size: 2.2rem;
    font-weight: 700;
    text-align: center;
}
.meme-cat-heading>span{
    color: #001c79;
}

.meme-cat-search{
    padding: 20px;
    max-width: 600px;
    margin: auto;
}
.meme-cat-search>input{
    width: 100%;
    /* height: 30px; */
    font-size: 1.2rem;
    padding: 5px 10px;
    font-family: poppins;
    border-radius: 30px;
}

.meme-cat-content-point{
    padding: 15px;
    border-bottom: solid 1px rgb(200, 200, 200);
}

@media only screen and (max-width: 800px) {

    .meme-cat{
        padding: 0px 15px 50px ;
    }
    .meme-cat-heading{
        font-size: 1.6rem;
        text-align: center;
    }
    
    .meme-cat-search{
        padding: 20px;
        max-width: 600px;
        margin: auto;
    }
    .meme-cat-search>input{
        font-size: 1rem;
        padding: 5px 10px;
        font-family: poppins;
        border-radius: 30px;
    }
    
    .meme-cat-content-point{
        padding: 10px 0;
        border-bottom: solid 1px rgb(200, 200, 200);
        font-size: .9rem;
    }
    
    
    
}
