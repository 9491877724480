.faq{
    background: #bac9ff2f;
    background: #6773ff32;

}
.faq>div{
    max-width: 1200px;
    margin: auto;
}
.faq-head{
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 2rem;
    padding: 40px 0;
    font-weight: 700;
}
.faq-head span{
    color: #001c79;
}

.faq-content{
    padding-bottom: 50px;
}


.faq-ques{
    font-size: 1.2rem;
    font-weight: 600;
}

.faq-ans>span{
    font-size: 1.1rem;
    font-weight: 600;
}
.faq-ans{
    margin-bottom: 30px;
}

@media only screen and (max-width: 800px) {
    
    
    .faq-head{
        font-size: 1.6rem;
        padding: 30px 15px;
        line-height: 2rem;
    }
    
    .faq-content{
        padding: 0px 15px 30px;
        font-size: .9rem;
    }
    

    
.faq-ques{
    font-size: 1.1rem;
}

.faq-ans>span{
    font-size: 1rem;
    
}
.faq-ans{
    margin-bottom: 20px;
}
    
    
    
}
