.our-price>div{
    max-width: 1200px;
    margin: 80px auto;
}
.our-price-heading{
    text-align: center;
    font-size: 2.2rem;
    font-weight: 700;
}
.our-price-heading>span{
color: #001c79;
}
.our-price-head-text{
    text-align: center;
}

.our-price-col{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: baseline;
    padding: 20px 0;
}

.our-price-col-1,
.our-price-col-2,
.our-price-col-3{
    /* border: solid 1px; */
    height: 360px;
}

.our-price-col-1{
    border-radius: 50px 0 0 0;
}

.our-price-col-1 .flip-card-front,
.our-price-col-1 .flip-card-back{
    border-radius: 50px 0 0 0;  
    border-top: solid 5px #000326;
    border-bottom: solid 5px #000326;
    border-left: solid 5px #000326;

}

.our-price-col-2{
    height: 420px;
    border-radius: 50px 50px 0 0 ;
}

.our-price-col-2 .flip-card-front,
.our-price-col-2 .flip-card-back{
    border-radius: 50px 50px 0 0 ;
    border: solid 5px #000326;


}

.our-price-col-3{
    border-radius: 0 50px 0 0;
}


.our-price-col-3 .flip-card-front,
.our-price-col-3 .flip-card-back{
    border-radius: 0 50px 0 0;
    border-top: solid 5px #000326;
    border-bottom: solid 5px #000326;
    border-right: solid 5px #000326;

}

/* ---------------------------------------- */

.flip-card {
    background-color: transparent;
    width: 100%;
    height: 100%;
    perspective: 1000px;
  }
  
  .flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    
  }
  
  .flip-card:hover .flip-card-inner {
    transform: rotateY(180deg);
  }
  
  .flip-card-front, .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }
  
  .flip-card-front {
    background-color: #00022c3b;
    color: black;
  }
  
  .flip-card-back {
    background-color: #001c79;
    background-color: #000326;
    color: white;
    transform: rotateY(180deg);
  }
  
  
  /* --------------------------- */
  
  .flip-card-back{
    padding: 30px;
    height: 100%;
    display: grid;
    align-items: center;
  }
  
.flip-card-back-heading{
    font-size: 1.4rem;
    font-weight: 700;
}

.flip-card-back-points{
    margin: 20px 0;
}

.flip-card-back-buttons{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
}
.flip-card-back-buttons>a:hover{
    text-decoration: underline;
    color: #98a1ff;
}
.flip-card-back-buttons>a{
    display: flex;
    align-items: center;
    color: white;
    border: solid;
    padding: 0 30px;
    text-decoration: none;
    font-size: 1.1rem;
    font-weight: 600;
    height: 40px;
}

/* ------------------------------ */

.flip-card-front{
    text-align: center;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 20px 0 0;
    justify-content: space-between;
    
}

.flip-card-front-heading{
    font-size: 1.4rem;
    font-weight: 700;
    
}

.flip-card-front-img{
    width: 100%;
    display: flex;
    justify-content: center;
}
.flip-card-front-img> img{
    height: 280px;
    position: absolute;
    bottom: 10px;
    z-index: 0;
}
.flip-card-front-img-2> img{
    height: 340px;
}

.flip-card-front-button{
    background-color: #000326;
    color: white;
    font-size: 1.1rem;
    font-weight: 600;
    padding: 8px;
    margin: 3px;
    z-index: 1;
}

@media only screen and (max-width: 800px) {
    .our-price>div{
        max-width: 1200px;
        margin: 50px auto;
    }
    .our-price-heading{
        font-size: 1.8rem;
    }
    .our-price-head-text{
        text-align: center;
    }
    
    .our-price-col{
        display: grid;
        grid-template-columns: 1fr;
        align-items: baseline;
        padding: 20px 0;
        gap: 20px;
        margin: auto;
    }
    
    .our-price-col-1 .flip-card-front,
    .our-price-col-1 .flip-card-back{
        border-radius: 20px;
        border: none;
    }
    
    .our-price-col-2{
        height: 250px;
        width: 100%;
        /* border-radius: 0 25px 25px  0 ; */
    }
    
    .our-price-col-2 .flip-card-front,
    .our-price-col-2 .flip-card-back{
        border-radius: 20px;
        border: none;
        
    }    
    
    .our-price-col-3 .flip-card-front,
    .our-price-col-3 .flip-card-back{
        border-radius: 20px;
        border: none;
    }
    
    .our-price-col-1,
    .our-price-col-2,
    .our-price-col-3{
        /* border: solid 1px; */
        height: 350px;
        width: 90%;
        /* border-radius: 30px !important; */
        margin: auto;
    }


    
  /* --------------------------- */
  
  .flip-card-back{
    padding: 20px 0px;
}

.flip-card-back-heading{
    font-size: 1.3rem;
    padding: 0 30px;
}

.flip-card-back-points{
    margin: 10px 0;
    font-size: .9rem;
    padding: 0 30px;
}

.flip-card-back-buttons{
    padding: 0 5px;
}
.flip-card-back-buttons>a{
    display: flex;
    align-items: center;
    color: white;
    border: solid 2px;
    padding: 0 25px;
    text-decoration: none;
    font-size: 1rem;
    font-weight: 600;
    height: 35px;
}
    


/* ------------------------------ */

.flip-card-front-img-2> img{
    width: 100%;
    height: 280px;
}

.flip-card-front-button{
    z-index: 0;
    border-radius: 20px;
}



    
}
