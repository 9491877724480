@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&family=Playpen+Sans:wght@100..800&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Rye&display=swap&family=IM+Fell+English+SC&display=swap&family=Jersey+25&display=swap");

*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: "Poppins";
  /* font-size: 1.3rem; */

  /* background: url(/static/media/site-bg.8e2a5da….png); */
  /* background: url("./media/images/MEMES-BACKGROUD1.png"); */
    background-attachment: fixed;
    /* background-position: 50%; */
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  
}

.slick-arrow{
  display: none !important;
}

.slick-list h3{
  background-color: aqua;
}

.slick-list h2{
  background-color: rgb(204, 227, 255);
}
.slick-next{
  position: relative;
}


@media only screen and (max-width: 800px) {}