.footer {
  /* background: url("../media/images/bg.png");
    background: url("../media/images/s3.webp"); */
  background-size: cover;
  background-position: center;
}

.footer > div {
  background-color: rgba(35, 35, 35, 0.862);
  background-color: #000326;
  color: white;
  padding: 50px 0 30px;
}

.footer-cols {
  max-width: 1300px;
  margin: auto;
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 50px;
}
.footer-col-1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 10px;
}

.footer-col-1-logo {
  /* font-size: 2.8rem;
  padding: 20px;
  font-family: "Jersey 25"; */
}

.footer-col-1-logo > img {
  width: 150px;
  margin: 10px 0;
  animation: anim-popoutin 3s ease infinite;

}
.footer-col-2 {
  display: flex;
  justify-content: space-around;
  gap: 40px;
}

.footer-col-2-col-heading {
  font-size: 1.3rem;
  font-weight: 600;
  text-decoration: underline;
}

.footer-col-2-col-points {
  height: 180px;
  display: grid;
  /* gap: 10px; */
}
.footer-col-2-col-points > div {
  display: flex;
  gap: 15px;
  align-items: center;
}

.footer-col-2-col-points > div a {
  color: white;
  text-decoration: none;
}
.footer-col-2-col-points > div a:hover {
  text-decoration: underline;
}
.footer-col-2-col-points > div > span {
  font-size: 1.5rem;
}
.footer-col-2-col-points > div > span > i {
  font-size: 1.1rem;
}

.footer-col-2-col-text {
  max-width: 290px;
}

.footer-cc {
  text-align: center;
  padding: 10px !important;
  background-color: rgba(0, 0, 0, 0.85) !important;
}

.footer-col-2-col-form > form {
  display: flex;
  background-color: white;
  height: 40px;
  width: 100%;
  border: white solid;
}
.footer-col-2-col-form > form > input {
  flex: 1;
  border: none;
}
.footer-col-2-col-form > form > button {
  background-color: #000326;
  border: white solid;
  border: none;
  color: white;
  padding: 0 20px;
}

@media only screen and (max-width: 800px) {
  .footer {
    background-size: cover;
    background-position: center;
  }

  .footer > div {
    padding: 30px 20px 30px;
  }

  .footer-cols {
    grid-template-columns: 1fr;
    gap: 40px;
  }

  .footer-col-1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 10px;
  }

  .footer-col-1-logo > img {
    /* width: 75%; */
    width: 130px;
    margin: auto;
  }
  .footer-col-2 {
    display: flex;
    justify-content: space-between;
    gap: 30px;
    flex-wrap: wrap;
    padding: 0 10px;
  }

  .footer-col-2-col-heading {
    font-size: 1.3rem;
    font-weight: 600;
    text-decoration: underline;
  }

  .footer-col-2-col-points {
    height: 180px;
    display: grid;
    /* gap: 10px; */
  }
  .footer-col-2-col-points > div {
    display: flex;
    gap: 15px;
    align-items: center;
  }

  .footer-col-2-col-points > div > span {
    font-size: 1.5rem;
  }
  .footer-col-2-col-points > div > span > i {
    font-size: 1.1rem;
  }
}
