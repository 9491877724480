.top-client>div{
    max-width: 1170px;
    margin: auto;
    position: relative;

}


.top-client-col-1{
    position: absolute;
    top: 0;
    z-index: 1;
}

.top-client-col-1>img{
    height: 200px;
}


.top-client-col-2-slider img{
    border-radius: 20px;
    height: 100px;
    width: auto;
}