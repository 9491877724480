
.keywords>div{
    max-width: 1200px;
    margin: auto;
}
.keywords-head{
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 2rem;
    padding: 40px 0;
    font-weight: 700;
}
.keywords-head span{
    color: #001c79;
}
.keywords-content{
    padding-bottom: 50px;
}

@media only screen and (max-width: 800px) {
    
    
    .keywords-head{
        font-size: 1.6rem;
        padding: 30px 15px;
        line-height: 2rem;
    }
    
    .keywords-content{
        padding: 0px 15px 30px;
        font-size: .9rem;
    }
    
}